import React, { useEffect, useCallback, useState } from 'react';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

import MapChart from "./MapChart";
import ReactTooltip from "react-tooltip";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Travel = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  imageFill,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {

  const [content, setContent] = useState("");

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Travel',
    paragraph: 'Travel is one of my biggest passions. Looking at a map and planning where to go next gives me a big sense of wonderlust.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <p className="text-xs">
            <span className="text-color-primary">Blue</span> - countries that I've traveled to. <br />
            <span className="text-color-secondary">Green</span> - countries that I plan on traveling to soon.
          </p>
          <div>
            <MapChart setTooltipContent={setContent} />
            <ReactTooltip>{content}</ReactTooltip>
          </div>
        </div>
      </div>
    </section>
  );
}

Travel.propTypes = propTypes;
Travel.defaultProps = defaultProps;

export default Travel;
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import '../../App.css'

import { useEffect, useCallback, useState } from 'react'

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Dropdown from 'react-dropdown';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Photography = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  pushLeft,
  ...props
}) => {

  var remotePhotos = [  
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      width: 4,
      height: 3
    }
  ]

  const [appState, setAppState] = useState({
    photos: []
  });

  const options = [
    'Favorites', 
    'France - Paris',
    'Germany - Berlin', 
    'Germany - Munich',
    'Italy - Florence', 
    'Italy - Rome', 
    'Italy - Venice', 
    'US - Arizona',
    'US - Colorado',
    'US - Nevada', 
    'US - New Mexico',
    'US - Pennsylvania',
    'US - South Carolina',
    'US - Utah',
    'US - Wyoming'
  ];
  const defaultOption = 'Click to filter photography';
  
  function getPhotos(keyword) {
    fetch('https://9vxko0m5se.execute-api.us-east-1.amazonaws.com/dev/photos?keyword=' + keyword)
    .then((response) => {
      response.json().then((data) => {
          var title = keyword.split('/')[1].charAt(0).toUpperCase() + keyword.split('/')[1].slice(1);
          var photosObject = [];
          for(const idx in data) {
            var locationArr = data[idx].split('/')
            var location = locationArr[locationArr.length - 1].split('_')[0]
            console.log(locationArr)
            console.log(location)
            switch(location) {
              case 'paris':
                title = 'Paris, France'
                break;
              case 'florence':
                title = 'Florence, Italy'
                break;
              case 'rome':
                title = 'Rome, Italy'
                break;
              case 'venice':
                title = 'Venice, Italy'
                break;
              case 'berlin':
                title = 'Berlin, Germany'
                break;
              case 'munich':
                title = 'Munich, Germany'
                break;
              case 'smokies':
                title = 'Great Smokey Mountains National Park, TN, United States'
                break;
              case 'sedona':
                title = 'Sedona, AZ, United States'
                break;
              case 'grandcanyon':
                title = 'Grand Canyon National Park, AZ, United States'
                break;
              case 'cavecreek':
                title = 'Cave Creek, AZ, United States'
                break;
              case 'redrocks':
                title = 'Red Rocks, NV, United States'
                break;
              case 'zion':
                title = 'Zion National Park, UT, United States'
                break;
              case 'arches':
                title = 'Arches National Park, UT, United States'
                break;
              case 'monument':
                title = 'Monument Valley, UT, United States'
                break;
              case 'canyonlands':
                title = 'Canyonlands National Park, UT, United States'
                break;
              case 'moab':
                title = 'Moab, UT, United States'
                break;
              case 'boulder':
                title = 'Boulder, CO, United States'
                break;
              case 'coloradosprings':
                title = 'Colorado Springs, CO, United States'
                break;
              case 'ouray':
                title = 'Ouray, CO, United States'
                break;
              case 'rockymountain':
                title = 'Rocky Mountain National Park, CO, United States'
                break;
              case 'santafe':
                title = 'Santa Fe, NM, United States'
                break;
              case 'bandelier':
                title = 'Bandelier National Monument, NM, United States'
                break;
              case 'teton':
                title = 'Grand Teton National Park, WY, United States'
                break;
              case 'yellowstone':
                title = 'Yellowstone National Park, WY, United States'
                break;
              case 'jackson':
                title = 'Jackson, WY, United States'
                break;
              case 'idaho':
                title = 'Idaho Falls, ID, United States'
                break;
              case 'charleston':
                title = 'Charleston, SC, United States'
                break;
              case 'savannah':
                title = 'Savannah, GA, United States'
                break;
              case 'amish':
                title = 'New Wilmington, PA, United States'
                break;
              case 'pittsburgh':
                title = 'Pittsburgh, PA, United States'
            }

            var photoObject = {
              "src": data[idx],
              "width": 3,
              "height": 2,
              "title": title
            }
            photosObject.push(photoObject);
          }
          setAppState({
            photos: photosObject
          });
      });
    })
    .catch(function(error) {
        console.log('Fetch Error:', error);
    });
  }

  useEffect(() => {
    getPhotos('best/')
  }, [setAppState])

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Photography',
    paragraph: 'I love exploring. Our world is beautiful, and photography has given me a fantastic medium in which to share my interactions with it. Photography updated regularly.'
  };

  function onFilterSelect(e) {
    const val = e.value
    var s3Prefix = ''
    
    switch(val) {
      case 'Favorites':
        s3Prefix = 'best/'
        break;
      case 'France - Paris':
        s3Prefix = 'france/paris/'
        break;
      case 'Germany - Berlin':
        s3Prefix = 'germany/berlin/'
        break;
      case 'Germany - Munich':
        s3Prefix = 'germany/munich/'
        break;
      case 'Italy - Florence':
        s3Prefix = 'italy/florence/'
        break;
      case 'Italy - Rome':
        s3Prefix = 'italy/rome/'
        break;
      case 'Italy - Venice':
        s3Prefix = 'italy/venice/'
        break;
      case 'US - Arizona':
        s3Prefix = 'unitedstates/arizona/'
        break;
      case 'US - Colorado':
        s3Prefix = 'unitedstates/colorado/'
        break;
      case 'US - Nevada':
        s3Prefix = 'unitedstates/nevada/'
        break;
      case 'US - New Mexico':
        s3Prefix = 'unitedstates/new_mexico/'
        break;
      case 'US - Pennsylvania':
        s3Prefix = 'unitedstates/pennsylvania/'
        break;
      case 'US - South Carolina':
        s3Prefix = 'unitedstates/south_carolina/'
        break;
      case 'US - Utah':
        s3Prefix = 'unitedstates/utah/'
        break;
      case 'US - Wyoming':
        s3Prefix = 'unitedstates/wyoming/'
        break;
    }

    let photosTmp = appState.photos
    setAppState({
      photos: photosTmp
    })

    getPhotos(s3Prefix)
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner reveal-from-bottom" style={{backgroundColor: '#25282C'}}>
                {/* <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — I ended up coming up with something that I like to call "isolated travel". If you are a human in the year 2020, you know exactly the concept: staying appropriately distanced from others. I took this idea and applied it to travel.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    <a href="https://jdepp265.medium.com/discovering-isolated-travel-a-silver-lining-of-the-pandemic-3372a6311a65">Discovering Isolated Travel: A Silver Lining of the Pandemic</a>
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Travel</span>
                </div> */}
                <div className="text-xs" style={{textAlign: 'center', cursor: 'pointer'}}>
                  <Dropdown placeholderClassName='text-color-primary' menuClassName='testimonial-item-footer has-top-divider' onChange={onFilterSelect} options={options} value={defaultOption} placeholder="Select an option" />
                </div>
              </div>
            </div>
          </div>
          <div className="reveal-from-bottom" data-reveal-delay="200">
            <Gallery photos={appState.photos} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={appState.photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
          {/* <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div>
                <h3 className="mt-0 mb-12">
                  Data-driven insights
                  </h3>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div>
                <h3 className="mt-0 mb-12">
                  Data-driven insights
                  </h3>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div>
                <h3 className="mt-0 mb-12">
                  Data-driven insights
                  </h3>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div> */}
        </div>
      </div>
    </section>
  );
}

Photography.propTypes = propTypes;
Photography.defaultProps = defaultProps;

export default Photography;
import React, { Component } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Pillars from '../components/sections/Pillars';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import BlogPosts from '../components/sections/BlogPosts';
import Cta from '../components/sections/Cta';
import Photography from '../components/sections/Photography';
import Bio from '../components/sections/Bio';
import Travel from '../components/sections/Travel';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <Bio topDivider />
      <Pillars topDivider />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      <BlogPosts topDivider />
      <Travel topDivider />
      <Photography topDivider />
      {/* <Cta split /> */}
    </>
  );
}

export default Home;
import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const BlogPosts = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Blog Posts',
    paragraph: 'Tech, travel, and the human condition. As I go through life, I try to put into words my lived experiences.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            
          <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — The ultimate sense of freedom is hopping in a car with a full tank of gas, some time on your hands, and wonderlust in your brain. A mentality of viewing life not in terms of a destination, but more of an ongoing story.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    <a href="https://jdepp265.medium.com/a-road-trip-across-the-united-states-d5553faaeba7">A Road Trip Across the United States</a>
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Travel</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — The creation of our custom-built utility called Pipeliner has enabled our SRE team to iterate new Spinnaker pipeline-template functionality faster, more efficiently, and with more confidence.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    <a href="https://engineering.upside.com/pipeliner-how-upside-manages-spinnaker-pipeline-templates-95a1b826d7f">Pipeliner: How Upside Manages Spinnaker Pipeline-Templates</a>
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Tech</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — I ended up coming up with something that I like to call "isolated travel". If you are a human in the year 2020, you know exactly the concept: staying appropriately distanced from others. I took this idea and applied it to travel.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    <a href="https://jdepp265.medium.com/discovering-isolated-travel-a-silver-lining-of-the-pandemic-3372a6311a65">Discovering Isolated Travel: A Silver Lining of the Pandemic</a>
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Travel</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

BlogPosts.propTypes = propTypes;
BlogPosts.defaultProps = defaultProps;

export default BlogPosts;
import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker
} from "react-simple-maps";
import { PatternLines } from "@vx/pattern";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

  const markers = [
    {
      markerOffset: -30,
      name: "Buenos Aires",
      coordinates: [-58.3816, -34.6037]
    },
    { markerOffset: 15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
    { markerOffset: 15, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
    { markerOffset: 15, name: "Santiago", coordinates: [-70.6693, -33.4489] },
    { markerOffset: 15, name: "Bogota", coordinates: [-74.0721, 4.711] },
    { markerOffset: 15, name: "Quito", coordinates: [-78.4678, -0.1807] },
    { markerOffset: -30, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
    { markerOffset: -30, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
    { markerOffset: 15, name: "Paramaribo", coordinates: [-55.2038, 5.852] },
    { markerOffset: 15, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
    { markerOffset: 15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
    { markerOffset: 15, name: "Lima", coordinates: [-77.0428, -12.0464] }
  ];

  const visited = [
    "USA",
    "ESP",
    "ITA",
    "GBR",
    "IRL",
    "DEU",
    "AUT",
    "CAN",
    "FRA",
    "BEL",
    "LUX",
    "DNK",
    "SWE"
  ];

  const nextToVisit = [
    "NOR",
    "NLD",
    "BEL",
    "CHE",
    "CZE",
    "HUN",
    "ISL"
  ]

const MapChart = ({setTooltipContent}) => {
  return (
    <div>
      <ComposableMap data-tip="">
        <ZoomableGroup zoom={1}>
          <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  var highlightedColor = "#F6F0E9"
                  var hoverColor = "#C0C0C0"
                  if(visited.indexOf(geo.properties.ISO_A3) !== -1) {
                    highlightedColor = "#5658DD"
                    hoverColor = "#3944BC"
                  }
                  else if(nextToVisit.indexOf(geo.properties.ISO_A3) !== -1) {
                    highlightedColor = "#1CB68B"
                    hoverColor = "#4AC948"
                  }
                  return (
                    <Geography
                      onClick={() => console.log(geo.properties.ISO_A3)}
                      fill={highlightedColor}
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        const { NAME, POP_EST } = geo.properties;
                        setTooltipContent(`${NAME}`);
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        hover: {
                          fill: hoverColor,
                          outline: "none"
                        }
                      }}
                    />
                  )
                })
              }
            </Geographies>
          {/* <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography key={geo.rsmKey} geography={geo} />
              ))
            }
          </Geographies>
          {markers.map(({ name, coordinates, markerOffset }) => (
            <Marker key={name} coordinates={coordinates}>
              <circle height='30%' width='30%' fill="#F00" stroke="#fff" strokeWidth={1} />
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: '1em' }}
              >
                {name}
              </text>
            </Marker>
          ))} */}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default MapChart;

import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import * as Icon from 'react-bootstrap-icons';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Pillar = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'How I try to keep myself well-rounded',
    paragraph: 'These are some facets in life that I feel very passionate about. They\'re what get me up in the morning, and what I think about as I fall asleep'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Tech
                    </h4>
                  <p className="m-0 text-sm">
                    As a <span className="text-color-primary">Cloud and DevOps Engineer</span> at a tech start-up in Paris, I have found my intersection of passion and a career. I work on our cloud infrastructure, devops pipelines, and really just try to "automate all the things". In my free time, you can find me coding frontend and backend projects, as well as keeping up-to-date with all the latest and greatest in the SRE space. Tech is rapidly changing, so to keep up, one must be a lifelong learner.  
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Travel
                    </h4>
                  <p className="m-0 text-sm">
                    I've recently gotten into travel in the past few years, and it's taken me to <span className="text-color-primary">Canada</span>, <span className="text-color-primary">Ireland</span>, <span className="text-color-primary">Spain</span>, <span className="text-color-primary">England</span>, <span className="text-color-primary">Germany</span>, <span className="text-color-primary">Austria</span>, <span className="text-color-primary">Italy</span> and <span className="text-color-primary">France</span>. I've also learned that travel doesn't have to be abroad, as I've managed to tick off <span className="text-color-primary">42 states in America</span>. In the near future, I'm looking forward to implementing <a href="https://www.remoteyear.com/blog/what-is-slow-travel#:~:text=Slow%20travel%20is%20an%20approach,local%20communities%20and%20the%20environment.">"slow travel"</a> which emphasizes the humanity of each country. Simply put, I've been bitten by the travel bug.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Language Learning
                    </h4>
                  <p className="m-0 text-sm">
                    As an American, I grew up seeing language learning as something boring that you had to do in school. I now see the opportunity to speak a country's native language as a key to a whole new level of experiencing the culture. <span className="text-color-primary">I'm currently learning Italian</span> and <span className="text-color-primary">French</span> and each day find myself obsessively listening to podcasts, music, and news in these languages. 
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-04.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Music
                    </h4>
                  <p className="m-0 text-sm">
                    I began playing <span className="text-color-primary">guitar</span> and <span className="text-color-primary">bass</span> in my early teens and found them to be a beautiful emotional outlet. Years later, I began teaching myself to play the <span className="text-color-primary">cello</span> because I've always loved the sound, and I find it almost therapeutic. This decade-long process taught me that if I could teach myself these instruments, I could teach myself anything. 
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Photography
                    </h4>
                  <p className="m-0 text-sm">
                    Photography has taught me that some things are more about the journey and less about the end-results. This is true in at least in my definition of the word. This is because what I benefit most from in photography is the process of getting out and exploring the world. You are most likely walking around and <span className="text-color-primary">noticing the small details</span> that you would've missed had you not been looking for them. The photos themselves are a great side effect of this process. 
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Relationships
                    </h4>
                  <p className="m-0 text-sm">
                    These are at the core of all human life. As a species, we're naturally inclined to form relationships with each other. In my life, <span className="text-color-primary">the people that I surround myself with</span> are who I draw inspiration from. Aside from these people, I also try my best to spark positivity in all my interactions with <span className="text-color-primary">strangers</span>. Be it an overly friendly "hello" to a cashier, meeting new people abroad, or breaking that awkward silence in an elevator.
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Pillar.propTypes = propTypes;
Pillar.defaultProps = defaultProps;

export default Pillar;